<template>
  <div>
    <div v-if="Object.keys(staff).length !== 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4>Staff Personal Information</h4>
            <hr>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-6"><b>Name</b></div>
                    <div class="col-md-6">{{ staff.name }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-6"><b>Phone No.</b></div>
                    <div class="col-md-6">{{ staff.phone_no }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-6"><b>Father`s Name</b></div>
                    <div class="col-md-6">{{ staff.father_name }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Father`s Phone No.</b></div>
                    <div class="col-md-6">{{ staff.father_phone_no }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Mother`s Name </b></div>
                    <div class="col-md-6">{{ staff.mother_name }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Mother`s Phone No</b></div>
                    <div class="col-md-6">{{ staff.mother_phone_no }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Address</b></div>
                    <div class="col-md-6">{{ staff.address }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Joining Date</b></div>
                    <div class="col-md-6">{{ staff.joining_date }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Current Salary</b></div>
                    <div class="col-md-6">{{ staff.current_salary }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Last Date of Increment</b></div>
                    <div class="col-md-6">{{ staff.last_date_of_increment }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Last Increment Amount</b></div>
                    <div class="col-md-6">{{ staff.last_increment_amount }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Hub </b></div>
                    <div class="col-md-6">{{ staff.hub.name }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Employee ID </b></div>
                    <div class="col-md-6">{{ staff.staff_employee_id }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Staff Type </b></div>
                    <div class="col-md-6">{{ staff.staff_type.name }}</div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="row">
                    <div class="col-md-6"><b>Working Area</b></div>
                    <div class="col-md-6">{{ staff.working_area }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4>Staff Leave History</h4>
            <hr>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-10">
                  <div class="card">
                    <div class="card-body">
                      <h5>Current Contract Date</h5>
                      [ {{ customDate(staff_contract_date.contract_start_date) }} - {{ customDate(staff_contract_date.contract_end_date)}} ]
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <div  v-for="(staff_leave_type, index) in staff_leave_types" :key="index" class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3"><b> {{ staff_leave_type.name }}</b></div>
                        <div class="col-md-1">|</div>
                        <div class="col-md-8">
                          Remaining {{ staff_leave_type.remaining_day_status === "FULL" ? staff_leave_type.duration : staff_leave_type.remaining_days }}  Days <br>
                          Automatically issued on  [ {{ customDate(staff_contract_date.contract_start_date) }} - {{ customDate(staff_contract_date.contract_end_date)}} ] ,
                          {{ staff_leave_type.duration }} Days
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4>Staff Images Information</h4>
            <hr>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <h4>Profile Picture</h4>
                  <img v-if="staff.profile_picture" :src="staff.profile_picture_url" class="rounded" width="300px" height="300px" alt="">
                </div>
                <div class="col-md-4">
                  <h4>NID</h4>
                  <img v-if="staff.nid" :src="staff.nid_url" class="rounded" width="300px" height="300px" alt="">
                </div>
                <div class="col-md-4">
                  <h4>Office ID Card</h4>
                  <img v-if="staff.office_id_image" :src="staff.office_id_image_url" class="rounded"  width="300px" height="300px" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <a-skeleton active :loading="loading"></a-skeleton>
      <h5 class="text-center text-secondary" v-if="staff.length === 0">
        <Empty :image="simpleImage"></Empty>
      </h5>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      staff: {},
      staff_contract_date: {},
      staff_leave_types: [],
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getStaff()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getStaff() {
      const staffId = this.$route.params.staff_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/staffs/view/' + staffId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.staff = data.staff
            this.staff_contract_date = data.staff_contract_date
            this.staff_leave_types = data.staff_leave_types
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.item:last-child .comma {
  display: none;
}
</style>
